// Standards in a school
export const standards = [
  "Play-School",
  "Nursery",
  "LKG",
  "UKG",
  "Class-1",
  "Class-2",
  "Class-3",
  "Class-4",
  "Class-5",
  "Class-6",
  "Class-7",
  "Class-8",
  "Class-9",
  "Class-10",
  "Class-11",
  "Class-12",
];

// Divisions within each standard
export const divisions = ["A", "B", "C", "D", "E", "F"];

// Departments in a school
export const departments = [
  "Science",
  "Mathematics",
  "English",
  "Social Studies",
  "Computer Science",
  "Physical Education",
  "Arts & Crafts",
  "Music",
  "Commerce",
  "Humanities",
  "Administration",
];

// Designations used in schools
export const designations = [
  "Principal",
  "Vice Principal",
  "Head Teacher",
  "Class Teacher",
  "Subject Teacher",
  "Librarian",
  "Lab Assistant",
  "Physical Trainer",
  "Counselor",
  "Accountant",
  "Clerk",
  "Peon",
  "Administrator",
];

// Gender options
export const genders = ["Male", "Female", "Other"];

// Common nationalities found in schools
export const nationalities = [
  "Indian",
  "American",
  "British",
  "Canadian",
  "Australian",
  "Chinese",
  "French",
  "German",
  "Japanese",
  "Russian",
  "Brazilian",
];

// Religious affiliations found in schools
export const religions = [
  "Hinduism",
  "Islam",
  "Christianity",
  "Sikhism",
  "Buddhism",
  "Jainism",
  "Judaism",
  "Other",
];

// Educational boards used in schools
export const boards = [
  "CBSE",
  "ICSE",
  "State Board",
  "IB",
  "Cambridge",
  "NIOS",
];

// Types of exams conducted in schools
export const examTypes = [
  "Unit Test",
  "Midterm",
  "Quarterly",
  "Half-Yearly",
  "Pre-Final",
  "Final",
  "Practical",
  "Oral",
  "Assignment",
  "Project Work",
];

export const mediums = ["English", "Hindi", "Gujarati", "Marathi", "Other"];

export const subject = [
  "Mathematics",
  "Science",
  "Physics",
  "Chemistry",
  "Biology",
  "Social Science",
  "History",
  "Geography",
  "Political Science",
  "Economics",
  "English",
  "Hindi",
  "Sanskrit",
  "Computer Science",
  "Environmental Studies",
  "Moral Science",
  "Physical Education",
  "Business Studies",
  "Accountancy",
  "Commerce",
  "Psychology",
  "Sociology",
  "Philosophy",
  "Home Science",
  "Fine Arts",
  "Music",
  "Dance",
  "Drawing",
  "Engineering Graphics",
  "Information Technology",
  "Electronics",
  "Biotechnology",
  "Agriculture",
  "Legal Studies",
  "Fashion Studies",
  "Entrepreneurship",
];

// Days

export const days = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday",
]